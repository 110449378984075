import { React } from 'react';
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn
} from 'mdb-react-ui-kit';
import SEO from './SEO';
import { auth, googleProvider, signInWithPopup } from '../services/firebase.config.js'

export default function Login(props) {
  const { layout, hostname, lang, langRo, langEn } = useWebsite();

  const signInWithGoogle = async () => {
    try {
    await signInWithPopup(auth, googleProvider);
    } catch (err){
      console.error(err);
    }
  };


  return (
      <>
      <SEO
        title='Login'
        description='Login page'
        name='Login'
        type="website"
      />
      <MDBContainer className="border-start border-end bg-white shadow-3 p-5">

        <MDBRow>
          <MDBCol xs='12' size='8' offsetMd='2' className="mt-5 mb-5 bg-white ">
          <MDBRow>
            <MDBCol >
              <MDBBtn outline color='dark' onClick={signInWithGoogle}>Login with Google</MDBBtn>
            </MDBCol>
          </MDBRow>
          </MDBCol>
        </MDBRow>


      </MDBContainer>
        </>
  );
}
