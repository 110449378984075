import React from 'react';
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBadge
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faMessages,
  faUsersRectangle,
  faFilePen,
  faFileContract,
  faCheck
} from '@fortawesome/pro-duotone-svg-icons'
import SEO from './SEO';
import ContactButton from './ContactButton'

export default function Workflow(props) {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()
  const success = {
    badge: {
      ro: 'Succes !',
      en: "Success !"
    },
    name: {
      ro: 'Am primit solicitarea ta',
      en: "We received your inquiry"
    },
    text: {
      ro: 'Analizăm datele pe care le-ai împărtășit cu noi și te vom contacta telefonic, sau prin email. ',
      en: "We are analyzing the data you shared with us and we'll contact you by phone, or by email."
    }
  }
  const workflow = [
    {
      id: '1',
      name: {
        ro: 'Programăm un meeting online',
        en: 'We schedule an online meeting'
      },
      text: {
        ro: 'Discutăm despre nevoile organizației tale și aflăm, în linii mari, ce dorești să obții. Inevitabil, vom avea întrebări suplimentare și lucruri de clarificat.',
        en: "We talk about your organization's needs and we find out what you want to obtain. Inevitably, we will have questions and we will need to clarify some things."
      }
    },
    {
      id: '2.a',
      name: {
        ro: 'Obții tu clarificările cerute de noi',
        en: 'You get the answers to our questions'
      },
      text: {
        ro: 'Mergi cu lista noastră de întrebări la echipa ta, discuți cu ei și obții răspunsurile de care avem nevoie.',
        en: 'You take our list of questions to your team, you talk to them and get the answers we need.'
      }
    },
    {
      id: '2.b',
      name: {
        ro: 'Workshop online',
        en: 'Workshop online'
      },
      text: {
        ro: 'Alcătuiești o listă cu persoanele relevante din echipa ta, iar noi moderăm un workshop pentru a obține informațiile.',
        en: 'You create a list of stakeholders from your team, then we moderate a workshop to obtain more information from them.'
      }
    },
    {
      id: '3',
      name: {
        ro: 'Creăm draftul contractului',
        en: 'We create the contract draft'
      },
      text: {
        ro: 'Creăm draftul contractului cadru, care ne va permite să încheiem anexe pentru livrabile bine definite.',
        en: 'We create the draft for the master agreement, which will allow us to sign annexes for well defined deliverables.'
      }
    },
    {
      id: '4',
      name: {
        ro: 'Semnăm contractul și prima anexă',
        en: 'We sign the contract and the first annex'
      },
      text: {
        ro: 'Definim foarte clar ce avem de livrat și care sunt condițiile de plată pentru primul livrabil, apoi semnăm.',
        en: 'We clearly define what should be delievered and how payments should be made, then we sign them.'
      }
    },
    {
      id: '5',
      name: {
        ro: 'Începe relația contractuală',
        en: 'Our contractual relationship starts'
      },
      text: {
        ro: 'Din acest moment putem colabora într-un mod bine definit, responsabil și previzibil.',
        en: 'We can now collaborate in a well defined manner, responsible and predictable.'
      }
    },
  ]

  return (
    <>
    <SEO
      title={ layout['brand'] }
      description={ layout.sections.workflow[lang] }
      name={ layout['brand'] }
      type="website"
    />



    <MDBContainer>

      {props.successfulContact ? (
        <MDBRow>
          <MDBCol size='12'>
            <div className="border rounded border-dark mt-5 mb-2 p-3" style={{ backgroundColor: layout['color_secondary'] }}>
              <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_secondary']}>
                { success.badge[lang] }
              </MDBBadge>
              <p className='d-flex align-items-center justify-content-center'>
                <b>{ success.name[lang] }</b></p>
              <p className='ps-5 pe-5 text-center'>{ success.text[lang] }</p>
            </div>
          </MDBCol>
        </MDBRow>
      ) : (
        <MDBRow>
          <MDBCol>
          </MDBCol>
        </MDBRow>
      )}

      <MDBRow>
        <MDBCol size='12'>
          <div className="bg-white border rounded border-dark mt-5 mb-2 p-3">
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
              { workflow[0]['id'] }
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faCalendarDays} style={{color: layout['color_main'] }} />
              <b>{ workflow[0]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[0]['text'][lang]}</p>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol lg='6' sm='12'>
          <div className="bg-white border rounded border-dark mt-5 mb-2 p-3">
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
              { workflow[1]['id'] }
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faMessages} style={{color: layout['color_main'] }} />
              <b>{ workflow[1]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[1]['text'][lang]}</p>
          </div>
        </MDBCol>
        <MDBCol lg='6' sm='12'>
          <div className="rounded border-dark mt-5 mb-2 p-3" style={{border: '1px dashed grey', backgroundColor: layout['color_faded'] }}>
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']} className="me-2">
              { workflow[2]['id'] }
            </MDBBadge>
            <MDBBadge style={{ backgroundColor: layout['color_secondary'] }}  color={layout['color_secondary']}>
              optional
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faUsersRectangle} style={{color: layout['color_main'] }} />
              <b>{ workflow[2]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[2]['text'][lang]}</p>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size='12'>
          <div className="bg-white border rounded border-dark mt-5 mb-2 p-3">
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
              { workflow[3]['id'] }
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faFilePen} style={{color: layout['color_main'] }} />
              <b>{ workflow[3]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[3]['text'][lang]}</p>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size='12'>
          <div className="bg-white border rounded border-dark mt-5 mb-2 p-3">
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
              { workflow[4]['id'] }
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faFileContract} style={{color: layout['color_main'] }} />
              <b>{ workflow[4]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[4]['text'][lang]}</p>
          </div>
        </MDBCol>
      </MDBRow>

      <MDBRow>
        <MDBCol size='12'>
          <div className="bg-white border rounded border-dark mt-5 mb-5 p-3">
            <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
              { workflow[5]['id'] }
            </MDBBadge>
            <p className='d-flex align-items-center justify-content-center'>
              <FontAwesomeIcon size='lg' className='me-2' icon={faCheck} style={{color: layout['color_main'] }} />
              <b>{ workflow[5]['name'][lang]}</b></p>
            <p className='ps-5 pe-5 text-center'>{ workflow[5]['text'][lang]}</p>
          </div>
        </MDBCol>
      </MDBRow>

    </MDBContainer>
    <ContactButton />
    </>
  );
}
