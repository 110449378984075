import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBBtnGroup,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import { useWebsite } from '../hooks/useWebsite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { auth, onAuthStateChanged } from '../services/firebase.config.js'


export default function NavBar() {
  const [showBasic, setShowBasic] = useState(false);
  const { layout, hostname, lang, langRo, langEn } = useWebsite()
  const [welcome, setWelcome] = useState (null)
  const location = useLocation()
  useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              // const uid = user.uid;
              // ...
              setWelcome(user.email)
            } else {
              // User is signed out
              // ...
              setWelcome(null)
            }
          });

    }, [])

  // (style= { borderBottom: "2px solid " + layout['color_accent'] })

  return (
    <MDBNavbar expand='lg'  className='xsticky-top pb-4 pt-4' style={{ backgroundColor: layout['color_main'], borderBottom: "2px solid " + layout['color_accent'] }}>
      <MDBContainer fluid>
        <MDBNavbarBrand className='text-white me-5'>
          <Link className="text-white me-3" to="/">
            <FontAwesomeIcon className='me-2' icon={layout['icon']} style={{ color: layout['color_accent']}} /><b>{ layout['brand'] }</b>
          </Link>
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <FontAwesomeIcon icon={icon({name: 'bars', family: 'duotone', style: 'solid'})} />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>

          {/*
            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='#' className='text-white me-3'>
                { layout.sections.services[lang] }
              </MDBNavbarLink>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBNavbarLink active aria-current='page' href='#' className='text-white me-3'>
                { layout.sections.examples[lang] }
              </MDBNavbarLink>
            </MDBNavbarItem>
            */}

            {(hostname !== layout['umbrella_hostname']) ? (
              <>
              <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/workflow" style={(location.pathname.startsWith('/workflow')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} } >
                  { layout.sections.workflow[lang] }
                </Link>
              </MDBNavbarItem>


              <MDBNavbarItem>
              <Link className="nav-link text-white me-3" to="/whatyouget" style={(location.pathname.startsWith('/whatyouget')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                { layout.sections.whatyouget[lang] }
              </Link>
              </MDBNavbarItem>

               <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/about" style={(location.pathname.startsWith('/about')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                  { layout.sections.about[lang] }
                </Link>
              </MDBNavbarItem>

              {/*<MDBNavbarItem>
               <Link className="nav-link text-white me-3" to="/blog" style={(location.pathname.startsWith('/blog')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                 { layout.sections.blog[lang] }
               </Link>
             </MDBNavbarItem>*/}

              <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/contact" style={(location.pathname.startsWith('/contact')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                  { layout.sections.contact[lang] }
                </Link>
              </MDBNavbarItem>
              </>
            ) : (
              <>
              <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/projects" style={(location.pathname.startsWith('/projects')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                  { layout.sections.projects[lang] }
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/software" style={(location.pathname.startsWith('/software')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                  { layout.sections.software[lang] }
                </Link>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <Link className="nav-link text-white me-3" to="/cloud" style={(location.pathname.startsWith('/cloud')) ? {borderBottom: "2px solid " + layout['color_secondary']} : {borderBottom: "2px solid " + layout['color_main']} }>
                  { layout.sections.cloud[lang] }
                </Link>
              </MDBNavbarItem>
              
              </>
            )}


          </MDBNavbarNav>

          <span className="text-white me-2">{welcome}</span>

          <MDBBtnGroup aria-label='Language switcher' shadow='0'>
            <MDBBtn size='sm' outline color='light' onClick={langRo} style={{ cursor: 'pointer' }}>RO</MDBBtn>
            <MDBBtn size='sm' outline color='light' onClick={langEn} style={{ cursor: 'pointer' }} className="me-3">EN</MDBBtn>
          </MDBBtnGroup>

        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
