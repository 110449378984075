import React from 'react';
import { useWebsite } from '../hooks/useWebsite';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight
} from '@fortawesome/pro-duotone-svg-icons'

export default function ContactButton() {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()

  return (
    <>
    {(hostname !== 'octaviangherghinis.com') ? (
      <>
      <div className="p-5 d-flex justify-content-center">
        <MDBBtn color={ layout['color_main'] } style={{ backgroundColor: layout['color_main'] }}>
          <Link className="text-white" to="/contact">
            <FontAwesomeIcon size='lg' className='me-2' icon={faArrowRight} style={{color: layout['color_primary'] }} />{ layout.sections.contact[lang] }
          </Link>
        </MDBBtn>
      </div>
      </>
    ) : (
      <>
      </>
    )}
    </>
  );
}
