import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSelect,
  MDBTextArea,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBToast
} from 'mdb-react-ui-kit';
import { MDBCaptcha } from 'mdb-react-captcha';
import { saveContactForm } from '../services/firebase.config.js'


import SEO from './SEO';

export default function Contact(props) {

  const navigate = useNavigate()

  const { layout, hostname, lang, langRo, langEn } = useWebsite()

  //const [successfulContact, setSuccessfulContact] = useState (false)

  const [firstName, setFirstName] = useState (JSON.parse(localStorage.getItem("form_first_name")) || "");
  const [lastName, setLastName] = useState (JSON.parse(localStorage.getItem("form_last_name")) || "");
  const [email, setEmail] = useState (JSON.parse(localStorage.getItem("form_email")) || "");
  const [phone, setPhone] = useState (JSON.parse(localStorage.getItem("form_phone")) || "");
  const [company, setCompany] = useState (JSON.parse(localStorage.getItem("form_company")) || "");
  const [employees, setEmployees] = useState (JSON.parse(localStorage.getItem("form_employees")) || 1);
  const [projectIdea, setProjectIdea] = useState (JSON.parse(localStorage.getItem("form_project_idea")) || "");
  const [currentSituation, setCurrentSituation] = useState (JSON.parse(localStorage.getItem("form_current_situation")) || "");
  const [currentIssues, setCurrentIssues] = useState (JSON.parse(localStorage.getItem("form_current_issues")) || "");

  const [formErrors, setFormErrors] = useState (false)
  const [saveErrors, setSaveErrors] = useState (false)

  const sendFormData = async () => {

      let form_object = {
        channel: layout['brand'],
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        company: company,
        employees: employees,
        project_idea: projectIdea,
        current_situation: currentSituation,
        current_issues: currentIssues,
      }
      // Because this is a Promise, we don't use try/catch
      await saveContactForm(form_object).then(
        // Instead we use .then with two arguments,
        (result) => {
          // One for success
          setFirstName("")
          setLastName("")
          setEmail("")
          setPhone("")
          setCompany("")
          setEmployees("")
          setProjectIdea("")
          setCurrentSituation("")
          setCurrentIssues("")
          localStorage.clear()
          props.setSuccessfulContact(true);
          navigate('/workflow')
        },
        (error) => {
          // One for error
          console.log('ERROR while saving the form data: ', error.toString());
          setSaveErrors(true);
        }
      );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationStatus = [];
    validationStatus.push(e.target.elements[0].validity.valid);
    validationStatus.push(e.target.elements[1].validity.valid);
    validationStatus.push(e.target.elements[2].validity.valid);
    validationStatus.push(e.target.elements[3].validity.valid);
    validationStatus.push(e.target.elements[4].validity.valid);
    validationStatus.push(e.target.elements[5].validity.valid); // MDBootstrap form shenanigans doesnt allow usage of a child id
    validationStatus.push(e.target.elements[6].validity.valid);
    validationStatus.push(e.target.elements[7].validity.valid);
    validationStatus.push(e.target.elements[8].validity.valid);
    let formValidator = arr => arr.every(v => v === true);
    let formIsValid = formValidator(validationStatus);
    if (formIsValid) {
      sendFormData();
    } else {
      setFormErrors(true);
    }
  }

  const contact = {
    form_error: {
      title: {
        ro: 'Formularul are erori!',
        en: 'Form errors!'
      },
      time: {
        ro: 'Chiar acum',
        en: "Just now"
      },
      text: {
        ro: 'Te rugăm să corectezi erorile din chenarele roșii.',
        en: "Please correct the errors in the red boxes."
      }
    },
    send_error: {
      title: {
        ro: 'Eroare trimitere',
        en: 'Sending error'
      },
      time: {
        ro: 'Chiar acum',
        en: "Just now"
      },
      text: {
        ro: 'Te rugăm să încerci mai târziu. Nu-ți fă griji, am salvat datele din formular în browserul tău. Trebuie doar să te întorci la site și să dai submit acestui formular când ai un minut liber.',
        en: "Please try again later. Don't worry, we saved the form data to your browser. You only have to come back to the website and submit this form again whenever you have a minute."
      }
    },
    headline: {
      ro: 'Formular de contact',
      en: "Contact form"
    },
    first_name: {
      ro: 'Prenume',
      en: "First name"
    },
    last_name: {
      ro: 'Nume',
      en: "Last name"
    },
    email: {
      ro: 'Email',
      en: "Email"
    },
    phone: {
      ro: 'Număr de telefon',
      en: "Phone number"
    },
    company: {
      ro: 'Numele firmei',
      en: "Company name"
    },
    employees: {
      ro: 'Număr de angajați',
      en: "Number of employees"
    },
    project_idea: {
      ro: 'Ideea de proiect',
      en: "Project idea"
    },
    current_situation: {
      ro: 'Situația actuală',
      en: "Current situation"
    },
    current_issues: {
      ro: 'Cele mai mari provocări',
      en: "Greatest challenges"
    },
    send: {
      ro: 'Trimite',
      en: 'Send'
    }
  }

  useEffect(() => {
    localStorage.setItem("form_first_name", JSON.stringify(firstName));
  }, [firstName]);

  useEffect(() => {
    localStorage.setItem("form_last_name", JSON.stringify(lastName));
  }, [lastName]);

  useEffect(() => {
    localStorage.setItem("form_email", JSON.stringify(email));
  }, [email]);

  useEffect(() => {
    localStorage.setItem("form_phone", JSON.stringify(phone));
  }, [phone]);

  useEffect(() => {
    localStorage.setItem("form_company", JSON.stringify(company));
  }, [company]);

  useEffect(() => {
    localStorage.setItem("form_employees", JSON.stringify(employees));
  }, [employees]);

  useEffect(() => {
    localStorage.setItem("form_project_idea", JSON.stringify(projectIdea));
  }, [projectIdea]);

  useEffect(() => {
    localStorage.setItem("form_current_situation", JSON.stringify(currentSituation));
  }, [currentSituation]);

  useEffect(() => {
    localStorage.setItem("form_current_issues", JSON.stringify(currentIssues));
  }, [currentIssues]);

  return (
    <>
    <MDBToast
        color='danger'
        position='top-right'
        delay={2000}
        appendToBody
        show={formErrors}
        headerContent={
          <>
            <strong className='me-auto'>{ contact.form_error.title[lang] }</strong>
            <small>{ contact.form_error.time[lang] }</small>
          </>
        }
        bodyContent={ contact.form_error.text[lang] }
      />
      <MDBToast
          color='danger'
          position='top-right'
          delay={2000}
          appendToBody
          show={saveErrors}
          headerContent={
            <>
              <strong className='me-auto'>{ contact.send_error.title[lang] }</strong>
              <small>{ contact.send_error.time[lang] }</small>
            </>
          }
          bodyContent={ contact.send_error.text[lang] }
        />
    <SEO
      title={ layout['brand'] }
      description={ layout.sections.contact[lang] }
      name={ layout['brand'] }
      type="website"
    />
    <MDBContainer>
      <MDBRow>
        <MDBCol xs='12' md='6' offsetMd='3' className="p-5">
          <h2>{ contact.headline[lang] }</h2>
          <hr />
          <MDBValidation onSubmit={handleSubmit}>
            <MDBRow className='mt-4 mb-4'>
              <MDBCol>
                  <MDBValidationItem feedback=''>
                    <MDBInput className='' type='text' id='first_name' label={ contact.first_name[lang] }
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    />
                  </MDBValidationItem>
              </MDBCol>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='text' id='last_name' label={ contact.last_name[lang] }
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='email' id='email' label={ contact.email[lang] }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='text' id='phone' label={ contact.phone[lang] }
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='text' id='company' label={ contact.company[lang] }
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBSelect id='employees' label={ contact.employees[lang] }
                    data={[
                      { text: '1', value: 1, defaultSelected: true },
                      { text: '2 - 5', value: 5 },
                      { text: '6 - 10', value: 10 },
                      { text: '11 - 20', value: 20 },
                      { text: '21 - 50', value: 50 },
                      { text: '51 - 100', value: 100 },
                      { text: '101 - 200', value: 200 },
                      { text: '201 - 500+', value: 500 },
                    ]}
                    visibleOptions="9"
                    value={employees}
                    onValueChange={(e) => setEmployees(e.value)}
                    validFeedback=''
                    validation
                    required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='project_idea' label={ contact.project_idea[lang] } rows={10}
                  value={projectIdea}
                  onChange={(e) => setProjectIdea(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='current_situation' label={ contact.current_situation[lang] } rows={5}
                  value={currentSituation}
                  onChange={(e) => setCurrentSituation(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='current_issues' label={ contact.current_issues[lang] } rows={5}
                  value={currentIssues}
                  onChange={(e) => setCurrentIssues(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBCaptcha className='g-recaptcha' siteKey='6Ldj4ZknAAAAABO6TvrI5vG874BWRL4wI-_dsCom' lang={ lang } />
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBBtn type='submit' block style={{backgroundColor: layout['color_main'] }}>
                  { contact.send[lang] }
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBValidation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </>
  );
}
