import React from 'react';
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHourglassStart,
  faGem,
  faShieldHalved,
  faPuzzle,
  faFileDoc,
  faChartNetwork,
  faHandshakeSimple,
  faCreditCard,
  faStar
} from '@fortawesome/pro-duotone-svg-icons'
import SEO from './SEO';
import ContactButton from './ContactButton'

export default function Whatyouget() {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()
  const whatyouget = [
    {
      id: '1',
      name: {
        ro: 'Un partener pe termen lung',
        en: 'A long term partner'
      },
      text: {
        ro: 'Fiecare nouă colaborare ne face tot mai sensibili la obiectivele tale de business.',
        en: 'Every new collboration makes us more attuned to your business objectives.'
      }
    },
    {
      id: '2',
      name: {
        ro: 'Calitate',
        en: 'Quality'
      },
      text: {
        ro: 'Luam în serios standardul ISO 9001 și îl aplicăm în tot ce facem.',
        en: 'We take the ISO 9001 standard seriously and we apply it in everything we do.'
      }
    },
    {
      id: '3',
      name: {
        ro: 'Securitate',
        en: 'Security'
      },
      text: {
        ro: 'Menținem certificarea ISO 27001 (Securitatea Informației) și te ajutăm și pe tine.',
        en: "We maintain the ISO 27001 (Information Security) certification and we'll help you too."
      }
    },
    {
      id: '4',
      name: {
        ro: 'Soluții adecvate',
        en: 'Fitness for purpose'
      },
      text: {
        ro: 'Nu ne place să livrăm fără să înțelegem de ce, așa că ne asigurăm întotdeauna că livrăm ceva util.',
        en: "We don't like to deliver without understanding why, so we always make sure to deliver something useful."
      }
    },
    {
      id: '5',
      name: {
        ro: 'Soluții documentate',
        en: 'Documented deliverables'
      },
      text: {
        ro: 'Ne asigurăm că tu și echipa ta aveți tot ce vă trebuie ca să folosiți cu succes ce am livrat noi.',
        en: "We make sure you and your team have everything you need in order to successfully use what we delivered."
      }
    },
    {
      id: '6',
      name: {
        ro: 'Diagrame arhitecturale',
        en: 'Architectural diagrams'
      },
      text: {
        ro: 'Tot ce livrăm are și o reprezentare vizuală, pe care o folosim ca să rămânem sincronizați cu obiectivele tale.',
        en: "Everything we deliver has a visual representation, which we use to stay synchronized to your objectives."
      }
    },
    {
      id: '7',
      name: {
        ro: 'Suport pentru tot',
        en: 'Support for everything'
      },
      text: {
        ro: 'Putem negocia orice formă de suport pentru soluțiile noastre, inclusiv servicii conexe.',
        en: "We can negotiate any form of support for our solutions, including related services."
      }
    },
    {
      id: '8',
      name: {
        ro: 'Variante de cost',
        en: 'Cost options'
      },
      text: {
        ro: 'Înțelegem perfect că uneori există constrângeri de buget sau alte priorități precum livrarea rapidă.',
        en: "We understand sometimes there are budget constraints or other priorities such as a speedy delivery."
      }
    },
    {
      id: '9',
      name: {
        ro: 'Folosim metodologia corectă',
        en: 'We use the proper workflow'
      },
      text: {
        ro: 'Managerii noștri certificați PRINCE2® și PMP® vor alege întotdeauna corect între Agile, Waterfall și Hibrid.',
        en: "Our PRINCE2® and PMP® certified managers will always make the right choice between Agile, Waterfall and Hybrid."
      }
    },
  ]

  return (
    <>
    <SEO
      title={ layout['brand'] }
      description={ layout.sections.whatyouget[lang] }
      name={ layout['brand'] }
      type="website"
    />
    <MDBContainer>
      <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-4">

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faHourglassStart} style={{color: layout['color_main'] }} />
                { whatyouget[0]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[0]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faGem} style={{color: layout['color_main'] }} />
                { whatyouget[1]['name'][lang]}
                </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[1]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faShieldHalved} style={{color: layout['color_main'] }} />
                { whatyouget[2]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[2]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faPuzzle} style={{color: layout['color_main'] }} />
                { whatyouget[3]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[3]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faFileDoc} style={{color: layout['color_main'] }} />
                { whatyouget[4]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[4]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faChartNetwork} style={{color: layout['color_main'] }} />
                { whatyouget[5]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[5]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faHandshakeSimple} style={{color: layout['color_main'] }} />
                { whatyouget[6]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[6]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' border='dark' background='white' className='h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faCreditCard} style={{color: layout['color_main'] }} />
                { whatyouget[7]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[7]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard shadow='0' style={{border: '2px solid ' + layout['color_secondary'], backgroundColor: layout['color_main']}} className='text-white h-100 mb-3'>
            <MDBCardBody>
              <MDBCardTitle>
                <FontAwesomeIcon size='lg' className='me-2' icon={faStar} style={{color: 'white' }} />
                { whatyouget[8]['name'][lang]}
              </MDBCardTitle>
              <hr />
              <MDBCardText>
                { whatyouget[8]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

      </MDBRow>
    </MDBContainer>
    <ContactButton />
    </>
  );
}
