import React from 'react';
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faRobot,
  faAlien,
  faClock,
  faGaugeHigh,
  faThumbsUp
} from '@fortawesome/pro-duotone-svg-icons'
import SEO from './SEO';
import ContactButton from './ContactButton'

export default function About(props) {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()

  let true_brand = layout['brand']
  if (props.override_brand) {
    true_brand = props.override_brand
  }
  const about = [
    {
        name: {
          ro: 'Partenerul tău',
          en: "Your partner"
        },
        text: {
          ro: 'Un expert este alocat ca Single Point Of Contact (SPOC) pentru fiecare contract.',
          en: "An expert is allocated as a Single Point Of Contact (SPOC) for each contract."
        }
    },
    {
        name: {
          ro: 'Contractor 1',
          en: "Contractor 1"
        },
        text: {
          ro: 'În funcție de fiecare contract, unele livrabile pot fi alocate unor contractori.',
          en: "Given contract specifics, some deliverables may be handled by contractors."
        }
    },
    {
        name: {
          ro: 'Contractor 2',
          en: "Contractor 2"
        },
        text: {
          ro: 'În funcție de fiecare contract, unele livrabile pot fi alocate unor contractori.',
          en: "Given contract specifics, some deliverables may be handled by contractors."
        }
    }
  ]
  const portfolio = {
    ro: 'Exemple de proiecte implementate cu succes',
    en: "Examples of successfully implemented projects"
  }
  const experience = {
    'Software Architect': [
      {
        id: 1,
        name: {
          ro: 'Aplicație de Management de Proiect (SaaS)',
          en: "Project Management SaaS"
        },
        text: {
          ro: 'O aplicație web pentru managementul proiectelor care permite managerilor de proiect și echipelor lor să aplice majoritatea tehnicilor și principiilor conținute în metodologiile PRINCE2® și PMP®.',
          en: "A project management web application that allows project managers and their teams to apply the majority of techniques and principles taught by the PRINCE2® and PMP® methodologies."
        },
        tags: ['Python', 'Agile', 'PRINCE2', 'PMP'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '3000+'
            },
            kpi: {
              ro: 'Tehnici implementate',
              en: "Implemented techniques",
              value: '80+%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '92'
            },
        },
      },
      {
        id: 2,
        name: {
          ro: 'Robot software care utilizează un site web',
          en: "Software robot that uses a website"
        },
        text: {
          ro: 'Folosind o tehnologie care permite controlul browserului, un robot software se autentifică pe un site web, apoi efectuează diverse operații în funcție de parametrii configurați.',
          en: "Using a technology that allows full control of a browser, a software robot is logging into a website, then performs various operations according to its configured parameters."
        },
        tags: ['Python', 'Robotframework', 'Selenium', 'RPA'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '210'
            },
            kpi: {
              ro: 'Operațiuni pe zi',
              en: "Daily operations",
              value: '1500+'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '96'
            },
        },
      },
      {
        id: 3,
        name: {
          ro: 'Migrare la Google Workspace for Business',
          en: "Migration to Google Workspace for Business"
        },
        text: {
          ro: 'Achiziționarea mai multor domenii .ro, configurarea Google Workspace pentru utilizarea acelor domenii pentru adrese de email, configurarea de aliasuri și liste de distribuție, configurarea permisiunilor necesare atat pentru email cât și pentru Google Drive for Business, implementare securității datelor.',
          en: "Buying multiple .ro domains, configuring Google Workspace to use those domains for email addresses, configuring aliases and distribution lists, configuring the required permissions for email and Google Drive for Business, implementing security."
        },
        tags: ['Email', 'Google', 'Gmail', 'Security', 'GDrive'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '80'
            },
            kpi: {
              ro: 'Căsuțe de mail și aliasuri',
              en: "Mail addresses and aliases",
              value: '63'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '97'
            },
        },
      },
      {
        id: 4,
        name: {
          ro: 'Aplicație web pentru E-Learning',
          en: "E-Learning web application"
        },
        text: {
          ro: 'O aplicație web care permite utilizatorilor să își structureze notițele, să își organizeze resursele, să creeze scopuri și jaloane pentru procesul de învățare.',
          en: "A web application that allows users to manage their notes, organize their resources and to create goals and milestones for their learning process."
        },
        tags: ['Reactjs', 'Firebase', 'Agile'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '430'
            },
            kpi: {
              ro: 'Employee engagement',
              en: "Implicarea angajaților",
              value: '38%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '93'
            },
        },
      },
      {
        id: 5,
        name: {
          ro: 'Măsurarea performanței serviciilor software',
          en: "Performance measurement for software services"
        },
        text: {
          ro: 'Un software optimizat, care măsoară la scară largă condițiile de funcționare ale unei liste de servicii. Printre măsurători se afla timpul de raspuns ICMP, timpul de stabilire al unei conexiuni TCP, numărul de hop-uri din rețea, procentul de pachete TCP pierdute și retransmise, răspunsul serverelor în funcție de protocolul folosit.',
          en: "An optimized software that measures the functioning parameters of a list of software services, at scale. Some metrics are the ICMP response time, the time to establish a TCP connection, number of network hops, percentage of lost and retransmitted TCP packets, the server reply given specific protocols."
        },
        tags: ['Python', 'Async', 'Kubernetes', 'Docker', 'Agile'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '160'
            },
            kpi: {
              ro: 'Verificări pe secundă',
              en: "Checks per second",
              value: '4000+'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '98'
            },
        },
      },

    ],
    'Cloud Architect': [
      {
        id: 1,
        name: {
          ro: 'Monitorizarea infrastructurii Cloud',
          en: "Cloud infrastructure monitoring"
        },
        text: {
          ro: 'Dashboarduri și alerte pentru resursele folosite din Cloud.',
          en: "Dashboards and alerts for Cloud resources."
        },
        tags: ['Google Cloud', 'Monitoring', 'Alerting'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '20 - 160+'
            },
            kpi: {
              ro: 'Resurse monitorizate',
              en: "Monitored resources",
              value: '90+%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '89'
            },
        },
      },
      {
        id: 2,
        name: {
          ro: 'Optimizarea costurilor resurselor din Cloud',
          en: "Cost optimization for Cloud resources"
        },
        text: {
          ro: 'Scalabilitate automată pentru mediul de producție (puține servere pentru puțini utilizatori, multe servere pentru mulți utilizatori), migrare la servicii managed cu Free Tier, configurare limite de buget.',
          en: "Automated salability for the production environment (few servers for few users, many servers for many users), migration to managed services that have a Free Tier, budget limit configuration."
        },
        tags: ['Google Cloud', 'Free Tier', 'Budget'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '450+'
            },
            kpi: {
              ro: 'Scădere costuri lunare',
              en: "Monthly expense decreased",
              value: '32%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '95'
            },
        },
      },
      {
        id: 3,
        name: {
          ro: 'Procesare Batch și stocare',
          en: "Batch processing and storage"
        },
        text: {
          ro: 'Flux de upload fișiere video, audio și foto, procesarea lor (transcodare, aplicare de watermarks), stocare fisere raw și rezultatele procesării în spații de stocare cu diferite nivele de cost.',
          en: "Pipeline to upload video, audio and photo files, processing them (transcoding, applying watermarks), storing the raw and processed files in storage spaces with different cost tiers."
        },
        tags: ['Google Cloud', 'Media', 'Terraform'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '80'
            },
            kpi: {
              ro: 'Preluare date',
              en: "Data ingestion",
              value: '50mbps'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '94'
            },
        },
      },
      {
        id: 4,
        name: {
          ro: 'Arhitectură bazată pe evenimente',
          en: "Event driven architecture"
        },
        text: {
          ro: 'Migrarea de la un flux de lucru manual la o arhitectură bazată pe evenimente, care folosește diverse servicii din Cloud doar atunci când este nevoie de ele.',
          en: "Migration from a manual workflow to an event driven architecture, using various Cloud services only when they are needed."
        },
        tags: ['Google Cloud', 'Eventarc', 'Terraform'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '250'
            },
            kpi: {
              ro: 'Inlocuire servicii',
              en: "Service replacement",
              value: '72%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '94'
            },
        },
      },
      {
        id: 5,
        name: {
          ro: 'Implementare OpenTelemetry în Google Cloud',
          en: "OpenTelemetry implementation in Google Cloud"
        },
        text: {
          ro: 'Preluarea datelor OpenTelemetry din aplicație în Google Cloud și crearea de vizualizări pentru ele.',
          en: "Ingestion of OpenTelemetry data from the app to Google Cloud and the creation of visualizations for them."
        },
        tags: ['Google Cloud', 'OpenTelemetry', 'Cloud Trace'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '470'
            },
            kpi: {
              ro: 'Cod analizat',
              en: "Code coverage",
              value: '68%'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '97'
            },
        },
      },
    ],
    'Project Manager': [
      {
        id: 1,
        name: {
          ro: 'Sistem de management al inventarului',
          en: "Inventory management system"
        },
        text: {
          ro: 'Un sistem care gestionează repere de la intrarea în țară, prin transport, depozitare și planificarea in ERPul companiei.',
          en: "An inventory management system starting at import and going through transport, storage and scheduling in the company's ERP."
        },
        tags: ['PRINCE2', 'Waterfall', 'Java'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '1200+'
            },
            kpi: {
              ro: 'Repere gestionate',
              en: "Managed inventory",
              value: '1 000 000+'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '90'
            },
        },
      },
      {
        id: 2,
        name: {
          ro: 'Sistem de management al obligațiilor contractuale',
          en: "Contractual obligations management system"
        },
        text: {
          ro: 'O aplicație web care gestionează obligații contractuale care decurg din contracte de închiriere locații.',
          en: "A web application that manages contractual obligations stemming from rental agreements."
        },
        tags: ['PRINCE2', 'PMP', 'Hybrid', 'Java'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '800+'
            },
            kpi: {
              ro: 'Numar de contracte gestionate',
              en: "Number of managed contracts",
              value: '2000+'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '92'
            },
        },
      },
      {
        id: 3,
        name: {
          ro: 'Certificare ISO',
          en: "ISO certification"
        },
        text: {
          ro: 'Un proiect de transformare internă care a rezultat într-o procedură de lucru pentru obținerea mai multor certificări ISO.',
          en: "A transformation project that resulted in a workflow for obtaining several ISO certifications."
        },
        tags: ['Business', 'Change management', 'Process'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '250+'
            },
            kpi: {
              ro: 'Procese noi',
              en: "New workflows",
              value: '21'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '99'
            },
        },
      },
      {
        id: 4,
        name: {
          ro: 'Business continuity & Disaster recovery',
          en: "Business continuity & Disaster recovery"
        },
        text: {
          ro: 'Îmbunătățirea rezilienței organizației prin creșterea procentului de servicii managed de Cloud și transformarea cunoașterii organizaționale in procese automatizate. Backup-uri, proceduri și program de simulări pentru Disaster Recovery.',
          en: "Improving the organization's resilience by increasing the percentage of managed Cloud services and transforming organizational knowledge into automated processes. Backups, workflows and simulation schedule for Disaster Recovery."
        },
        tags: ['Agile', 'Change manangement', 'Automation'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '120+'
            },
            kpi: {
              ro: 'Automatizări (operaționale și DR)',
              en: "Automations (operational and DR)",
              value: '81'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '97'
            },
        },
      },
      {
        id: 5,
        name: {
          ro: 'Flux DevOps',
          en: "DevOps pipeline"
        },
        text: {
          ro: 'Crearea și implementarea unui flux DevOps alcătuit din dezvoltare software, testare automată software, revizuire cod, instanțiere software în mediile de Staging și Producție, capabilitate de întoarcere la versiunea anterioară, scalabilitate și securitate.',
          en: "Creating and implementing a DevOps pipeline spanning software development, automated testing, code review, deployment to Staging and Production, rollback capabilities, scalability and security."
        },
        tags: ['Continuous Integration', 'Continuous Delivery', 'Agile'],
        metrics: {
            duration: {
              ro: 'Durată (în ore)',
              en: "Duration (hours)",
              value: '230'
            },
            kpi: {
              ro: 'Ciclu de livrare (ore)',
              en: "Cycle time (hours)",
              value: '110'
            },
            satisfaction: {
              ro: 'Scor de satisfacție',
              en: "Satisfaction score",
              value: '97'
            },
        },
      },
    ]
  }
  return (
    <>
    <SEO
      title={ layout['brand'] }
      description={ layout.sections.about[lang] }
      name={ layout['brand'] }
      type="website"
    />
    <MDBContainer>

      {(true_brand === layout['brand']) ? (
        <>
        <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-4">

        <MDBCol>
          <MDBCard>
          <MDBBadge style={{ backgroundColor: layout['color_main'] }}  color={layout['color_main']}>
            default
          </MDBBadge>
            <FontAwesomeIcon size='10x' className='m-5' icon={faUser} style={{color: layout['color_main'] }} />
            <MDBCardBody>
              <MDBCardTitle><b>{ about[0]['name'][lang]}</b></MDBCardTitle>
              <MDBCardText>
                { about[0]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard style={{border: '1px dashed grey', backgroundColor: layout['color_faded'] }}>
          <MDBBadge style={{ backgroundColor: layout['color_secondary'] }}  color={layout['color_secondary']}>
            optional
          </MDBBadge>
            <FontAwesomeIcon size='10x' className='m-5' icon={faRobot} style={{color: layout['color_secondary'] }} />
            <MDBCardBody>
              <MDBCardTitle className='text-muted'><b>{ about[1]['name'][lang]}</b></MDBCardTitle>
              <MDBCardText className='text-muted'>
                { about[1]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol>
          <MDBCard style={{border: '1px dashed grey', backgroundColor: layout['color_faded'] }}>
          <MDBBadge style={{ backgroundColor: layout['color_secondary'] }}  color={layout['color_secondary']}>
            optional
          </MDBBadge>
            <FontAwesomeIcon size='10x' className='m-5' icon={faAlien} style={{color: layout['color_secondary'] }} />
            <MDBCardBody>
              <MDBCardTitle className='text-muted'><b>{ about[2]['name'][lang]}</b></MDBCardTitle>
              <MDBCardText className='text-muted'>
                { about[2]['text'][lang]}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        </MDBRow>
        </>
      ) : (
        <>
        </>
      )}


      <MDBRow><MDBCol className="mt-5 mb-7 border-bottom"><h2>{ portfolio[lang] }</h2></MDBCol></MDBRow>


      {experience[true_brand].map((project, i) => {
        return (
      <MDBRow key={i}>
        <MDBCol>
          <section className="border bg-white overflow-hidden shadow-2 rounded-7 mb-7 mt-3">
            <div className="p-4 d-flex justify-content-between align-items-center border-bottom">
              <b>{ project.name[lang] }</b>
            </div>
            <div className="p-4 border-top" style={{backgroundColor: layout['color_faded'] }}>
              <section className="mb-2">
                { project.text[lang] }
                <span className="d-flex justify-content-end mt-3">
                  {project.tags.map((tag, i) => {
                    return (
                      <MDBBadge key={i} pill style={{backgroundColor: layout['color_main'] }} color={layout['color_main']} className="text-white me-2">{ tag }</MDBBadge>
                    )
                  })}
                </span>
              </section>
              <section>
              <MDBRow>
                <MDBCol md='4' className='mb-md-0 mt-2'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0 justify-content-center align-items-center'>
                          <div className='p-2 pt-2 pb-2 rounded-4 shadow-2-strong' style={{ backgroundColor: layout['color_secondary'] }}>
                            <FontAwesomeIcon swapOpacity size='lg' className='fa-fw' icon={faClock} style={{color: layout['color_main'] }} />
                          </div>
                        </div>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-muted mb-1'>{ project.metrics.duration[lang] }</p>
                          <h2 className='mb-0'>
                            { project.metrics.duration['value'] }
                          </h2>
                        </div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                <MDBCol md='4' className='mb-md-0 mt-2'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                        <div className='p-2 pt-2 pb-2 rounded-4 shadow-2-strong' style={{ backgroundColor: layout['color_secondary'] }}>
                          <FontAwesomeIcon swapOpacity size='lg' className='fa-fw' icon={faGaugeHigh} style={{color: layout['color_main'] }} />
                        </div>
                        </div>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-muted mb-1'>{ project.metrics.kpi[lang] }</p>
                          <h2 className='mb-0'>
                            { project.metrics.kpi['value'] }
                          </h2>
                        </div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>

                <MDBCol md='4' className='mb-md-0 mt-2'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                          <div className='p-2 pt-2 pb-2 rounded-4 shadow-2-strong' style={{ backgroundColor: layout['color_secondary'] }}>
                            <FontAwesomeIcon swapOpacity size='lg' className='fa-fw' icon={faThumbsUp} style={{color: layout['color_main'] }} />
                          </div>
                        </div>
                        <div className='flex-grow-1 ms-4'>
                          <p className='text-muted mb-1'>{ project.metrics.satisfaction[lang] }</p>
                          <h2 className='mb-0'>
                            { project.metrics.satisfaction['value'] }%
                          </h2>
                        </div>
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              </section>
            </div>
          </section>
        </MDBCol>
      </MDBRow>
    )
      })}
    </MDBContainer>
    <ContactButton />
    </>
  );
}
