import { useContext } from 'react'
import { WebsiteContext } from '../context/WebsiteContext'

/**
 * A hook that provides the final values of the context, and can also implement some logic.
 */
export const useWebsite = () => {
  const context = useContext(WebsiteContext)

  /**
   * If this hook is used outside a WebsiteContext Provider, we get an error, which
   * we need to handle.
   * This hook is used to wrap App itself so it should never meet this condition,
   * however error handling is always good for the future.
   */
   if (context === undefined) {
     throw new Error("useWebsite() must be used inside a WebsiteProvider")
   }

  return context
}
