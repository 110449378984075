import { createContext, useReducer } from 'react'
import { faCode, faChartPyramid, faDiagramProject, faCloudCheck, faUser, faBadgeCheck } from '@fortawesome/pro-duotone-svg-icons'
/**
 * Create a context that stores the website domain and the user-selectable language.
 */
export const WebsiteContext = createContext()

/** We have two-word domain variations with '-' between the words, so let's handle those. */
const hostname = window.location.hostname.replace('-', '');

const langReducer = (state, action) => {
  /**
   * state = current state, action = object passed to dispatch(), with type and property
   */
   switch(action.type) {
     /**
      * Returning ...state because state might have other values as well, aside from the one being modified.
      */
     case 'LANG_RO':
         return { ...state, lang: 'ro' }
     case 'LANG_EN':
         return { ...state, lang: 'en' }
     default:
         return state
   }

}

const umbrella_brand = "Octavian Gherghiniș"
const umbrella_hostname = "octaviangherghinis.com"

const siteBrand = {
  "softwarearchitect.ro": "Software Architect",
  "cloudarchitect.ro": "Cloud Architect",
  "proiect.ro": "Project Manager",
  "octaviangherghinis.com": "Octavian Gherghiniș"
}

const siteIcons = {
  "Project Manager": faChartPyramid,
  "Software Architect": faCode,
  "Cloud Architect": faDiagramProject,
  "Octavian Gherghiniș": faUser,
  "Graceful Efforts": faCloudCheck
}

const siteColors = {
  "Project Manager": ['#009a31', '#84cf96', '#ceffce'], //['black', 'grey', 'red'],
  "Software Architect": ['#FF8000', '#FFCF75', '#000000'], //['orange', '#FFD580', '#c04dff'],
  "Cloud Architect": ['#0191c8', '#74c2e1', '#e0f4ff'], //['#003366', '#6699cc', '#e0f4ff'] //['blue', 'lightblue', 'lime']
  "Octavian Gherghiniș": [ '#7579E7', '#A3D8F4', '#0F4C75'] //['#009a31', '#84cf96', '#ceffce']
}

/**
 * This function can be called to wrap an individual component or a tree of components.
 * 'children' is the component or tree this function wraps.
 */
export function WebsiteProvider({ children }) {
  /** Use triadic colors for main and accent. */
  const website_layout = {
    umbrella_brand: umbrella_brand,
    umbrella_hostname: umbrella_hostname,
    brand: siteBrand[hostname],
    icon: siteIcons[siteBrand[hostname]],
    color_main: siteColors[siteBrand[hostname]][0],
    color_secondary: siteColors[siteBrand[hostname]][1],
    color_accent: siteColors[siteBrand[hostname]][2],
    color_faded: '#F5F5F5',
    captcha_key: '6Ldj4ZknAAAAABO6TvrI5vG874BWRL4wI-_dsCom',
    footer_funnel: {
      "Software Architect": {
        ro: 'Ai nevoie și de alte servicii ?',
        en: 'Do you need other services as well ?'
      },
      "Cloud Architect": {
        ro: 'Ai nevoie și de alte servicii ?',
        en: 'Do you need other services as well ?'
      },
      "Project Manager": {
        ro: 'Ai nevoie și de alte servicii ?',
        en: 'Do you need other services as well ?'
      },
      "Octavian Gherghiniș": {
        ro: 'De la startupuri de 2 persoane la Fortune 500',
        en: 'From 2 person startups to Fortune 500'
      },
    },
    sections: {
      services: {
        ro: 'Servicii',
        en: 'Services'
      },
      examples: {
        ro: 'Exemple',
        en: 'Examples'
      },
      workflow: {
        ro: 'Cum lucrăm',
        en: 'Workflow'
      },
      whatyouget: {
        ro: 'Ce obții',
        en: 'What you get'
      },
      about: {
        ro: 'Despre',
        en: 'About'
      },
      blog: {
        ro: 'Blog',
        en: "Blog"
      },
      contact: {
        ro: 'Contact',
        en: 'Contact'
      },
      software: {
        ro: 'Software',
        en: "Software"
      },
      cloud: {
        ro: 'Cloud',
        en: "Cloud"
      },
      projects: {
        ro: 'Management de Proiect',
        en: "Project Management"
      }
    },
    footer_qualifications: [
      {
        name: "PMP® & PRINCE2®",
        icon: faBadgeCheck,
        text: {
          ro: 'Execuția potrivită în contextul corect.',
          en: "Proper exection in the right context."
        }
      },
      {
        name: "Google Cloud Architect",
        icon: faBadgeCheck,
        text: {
          ro: 'Oricine poate face lucruri la scara Google.',
          en: "Anyone can do things at Google scale."
        }
      },
    ],
    footer_links: [
      {
        href: 'http://softwarearchitect.ro',
        hostname: 'softwarearchitect.ro',
        brand: 'Software Architect',
        icon: siteIcons['Software Architect'],
        text: {
          ro: 'Pune calculatorul la treaba pentru firma ta.',
          en: 'Make the computer work for you.'
        }
      },
      {
        href: 'http://cloudarchitect.ro',
        hostname: 'cloudarchitect.ro',
        brand: 'Cloud Architect',
        icon: siteIcons['Cloud Architect'],
        text: {
          ro: 'Ruleaza software sau foloseste servicii de tip Cloud.',
          en: 'Run your software or use Cloud services.'
        }
      },
      {
        href: 'http://proiect.ro',
        hostname: 'proiect.ro',
        brand: 'Project Manager',
        icon: siteIcons['Project Manager'],
        text: {
          ro: 'Nu pierde controlul proiectelor tale.',
          en: "Don't lose control over your projects."
        }
      },
      {
        href: 'https://graceful-efforts.com',
        hostname: 'graceful-efforts.com',
        brand: 'Graceful Efforts',
        icon: siteIcons['Graceful Efforts'],
        text: {
          ro: 'Aplicație SaaS pentru managementul proiectelor.',
          en: "Project management SaaS app."
        }
      },
    ]
  }

  /**
  * useReducer() returns the initial state (lang: ro) and the dispatcher function (langReducer)
  */
  let default_lang = '';
  if (hostname !== "octaviangherghinis.com") {
    default_lang = 'ro'
  } else {
    default_lang = 'en'
  }
  const [state, dispatch] = useReducer(langReducer, {
    layout: website_layout,
    hostname: hostname,
    lang: default_lang
  })

  const langRo = () => {
    /**
     * type = operation name, property = the argument to changeLang($THIS_ONE)
     */
    dispatch({ type: 'LANG_RO' })
  }

  const langEn = () => {
    /**
     * type = operation name, property = the argument to changeLang($THIS_ONE)
     */
    dispatch({ type: 'LANG_EN' })
  }

  return (
    <WebsiteContext.Provider value={{ ...state, langRo, langEn }}>
      { children }
    </WebsiteContext.Provider>
  )
}
