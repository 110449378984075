import { Outlet } from 'react-router-dom';
import { MDBContainer } from 'mdb-react-ui-kit';
import NavBar from './NavBar';
import Footer from './Footer';

export default function Layout() {
  return (
    <>
    <NavBar />
    <MDBContainer fluid className="ps-0 pe-0">
    <Outlet />
    </MDBContainer>
    <Footer />
    </>
  )
}
