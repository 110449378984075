import { React, useState, useEffect } from 'react';
import { useParams } from "react-router";
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faPenToSquare
} from '@fortawesome/pro-duotone-svg-icons'
import SEO from './SEO';
import { getArticle } from '../services/firebase.config.js'
import parse from 'html-react-parser';
//import Error404 from '../components/Error404'
import Loading from '../components/Loading'

export default function Article(props) {
  const { layout, hostname, lang, langRo, langEn } = useWebsite();
  let { slug } = useParams();

  const [articlesFilter, setArticlesFilter] = useState ({limit: 1, slug: slug })
  const [articles, setArticles] = useState (undefined)

  const locales = {
    ro: 'ro-RO',
    en: 'en-EN'
  }

  useEffect(() => {
    if (articlesFilter) {
      getArticle(articlesFilter).then(
        (result) => {
          setArticles(result)
        },
        (error) => {

        }
      )
    }
  }, []);


  return (
    <>
    {(articles) ? (
      <>
      <SEO
        title={(layout['umbrella_hostname'] === hostname) ? (articles[0].title_en) : (articles[0].title_ro)}
        description={(layout['umbrella_hostname'] === hostname) ? (articles[0].text_en) : (articles[0].text_ro)}
        name={(layout['umbrella_hostname'] === hostname) ? (articles[0].title_en) : (articles[0].title_ro)}
        type="article"
      />
      <MDBContainer className="border-start border-end bg-white shadow-3 p-5">

        <MDBRow>
          <MDBCol xs='12' size='8' offsetMd='2' className="mt-5 mb-5 bg-white ">
          <MDBRow>
            <MDBCol>
              <h3 className="d-flex align-items-center"><b>{(layout['umbrella_hostname'] === hostname) ? (articles[0].title_en) : (articles[0].title_ro)}</b></h3>
              <p className="pb-4">
                <small className="text-muted">
                  <FontAwesomeIcon size='lg' className='me-2' icon={faCalendarDays} style={{color: layout['color_main'] }} />
                  {articles[0].timestamp.toDate().toLocaleString(locales[lang], { dateStyle: 'full' })}
                  {(articles[0].timestamp.toDate().toDateString() !== articles[0].modified_at.toDate().toDateString()) ? (
                    <>
                    <FontAwesomeIcon size='lg' className='ms-2 me-2' icon={faPenToSquare} style={{color: layout['color_main'] }} />
                    {articles[0].modified_at.toDate().toLocaleString(locales[lang], { dateStyle: 'full' })}
                    </>
                  ) : (
                    ""
                  )}
                </small></p>

              <section className="lh-lg mb-5">{(layout['umbrella_hostname'] === hostname) ? (parse(articles[0].text_en)) : (parse(articles[0].text_ro))}</section>

              <section className="lh-lg mb-5">{(layout['umbrella_hostname'] === hostname) ? (parse(articles[0].long_text_en || "")) : (parse(articles[0].long_text_ro || ""))}</section>
            </MDBCol>
          </MDBRow>
          </MDBCol>
        </MDBRow>


      </MDBContainer>
        </>
    ) : (
      <Loading />
    )}

   </>
  );
}
