import { React, useState, useEffect } from 'react';
import './App.css';
import { useWebsite } from './hooks/useWebsite'
import Layout from './components/Layout'
import LandingPage from './components/LandingPage'
import Workflow from './components/Workflow'
import Whatyouget from './components/Whatyouget'
import About from './components/About'
import Contact from './components/Contact'
import Articles from './components/Articles'
import Article from './components/Article'
import Login from './components/Login'
import AddArticle from './components/AddArticle'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { auth, onAuthStateChanged } from './services/firebase.config.js'

function App() {

  /**
   * useContext(WebsiteContext) is destructured into "value" from: <WebsiteContext.Provider value={{ color: 'blue' }}>
   */
  const { layout, hostname, lang, langRo, langEn } = useWebsite()
  const [successfulContact, setSuccessfulContact] = useState (false)

  const [welcome, setWelcome] = useState (null)
  useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              // const uid = user.uid;
              // ...
              setWelcome(user.email)
            } else {
              // User is signed out
              // ...
              setWelcome(null)
            }
          });

    }, [])

  return (
    <HelmetProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {(hostname !== layout['umbrella_hostname']) ? (
            <>
            <Route path="/"         element={<LandingPage />} />
            <Route path="/workflow" element={<Workflow successfulContact={successfulContact} setSuccessfulContact={setSuccessfulContact} />}    />
            <Route path="/whatyouget" element={<Whatyouget />}    />
            <Route path="/about" element={<About />}    />
            {/*<Route path="/blog" element={<Articles />}    />*/}
            <Route path="/blog/:slug" element={<Article slug />} />
            <Route path="/contact" element={<Contact successfulContact={successfulContact} setSuccessfulContact={setSuccessfulContact} />}    />
            </>
          ) : (
            <>
            <Route path="/"         element={<LandingPage />} />
            <Route path="/blog/:slug" element={<Article slug />} />
            <Route path="/software" element={<About override_brand="Software Architect" />}    />
            <Route path="/cloud" element={<About override_brand="Cloud Architect" />}    />
            <Route path="/projects" element={<About override_brand="Project Manager" />}    />
            </>
          )
          }
          <Route path="/login"         element={<Login />} />
          {welcome ? (
            <Route path="/add"         element={<AddArticle />} />
          ) : (
            ""
          )}
        </Route>
      </Routes>
    </Router>
    </HelmetProvider>
  );
}

export default App;
