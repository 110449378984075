import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSelect,
  MDBTextArea,
  MDBBtn,
  MDBValidation,
  MDBValidationItem,
  MDBToast,
  MDBDatepicker
} from 'mdb-react-ui-kit';
import { MDBCaptcha } from 'mdb-react-captcha';
import { saveArticleForm } from '../services/firebase.config.js'
import { auth, onAuthStateChanged } from '../services/firebase.config.js'


import SEO from './SEO';

export default function AddArticle(props) {

  const navigate = useNavigate()

  const { layout, hostname, lang, langRo, langEn } = useWebsite()

  //const [successfulContact, setSuccessfulContact] = useState (false)

  const [articleCategory, setArticleCategory] = useState (JSON.parse(localStorage.getItem("new_article_category")) || "Project");
  const [articleSlug, setArticleSlug] = useState (JSON.parse(localStorage.getItem("new_article_slug")) || "");
  const [titleRo, setTitleRo] = useState (JSON.parse(localStorage.getItem("new_article_title_ro")) || "");
  const [titleEn, setTitleEn] = useState (JSON.parse(localStorage.getItem("new_article_title_en")) || "");
  const [textRo, setTextRo] = useState (JSON.parse(localStorage.getItem("new_article_text_ro")) || "");
  const [textEn, setTextEn] = useState (JSON.parse(localStorage.getItem("new_article_text_en")) || "");
  const [longTextRo, setLongTextRo] = useState (JSON.parse(localStorage.getItem("new_article_long_text_ro")) || "");
  const [longTextEn, setLongTextEn] = useState (JSON.parse(localStorage.getItem("new_article_long_text_en")) || "");
  const [urlImg, setUrlImg] = useState (JSON.parse(localStorage.getItem("new_article_url_img")) || "");
  const [urlMore, setUrlMore] = useState (JSON.parse(localStorage.getItem("new_article_url_more")) || "");
  const [publishedAt, setPublishedAt] = useState (JSON.parse(localStorage.getItem("new_article_published_at")) || "");


  const [formErrors, setFormErrors] = useState (false)
  const [saveErrors, setSaveErrors] = useState (false)

  const sendFormData = async () => {

      let form_object = {
        author: userEmail,
        category: articleCategory,
        slug: articleSlug,
        title_ro: titleRo,
        title_en: titleEn,
        text_ro: textRo,
        text_en: textEn,
        long_text_ro: longTextRo,
        long_text_en: longTextEn,
        url_img: urlImg,
        url_more: urlMore,
        published_at: publishedAt,
      }
      // Because this is a Promise, we don't use try/catch
      await saveArticleForm(form_object).then(
        // Instead we use .then with two arguments,
        (result) => {
          // One for success
          setArticleCategory("")
          setArticleSlug("")
          setTitleRo("")
          setTitleEn("")
          setTextRo("")
          setTextEn("")
          setLongTextRo("")
          setLongTextEn("")
          setUrlImg("")
          setUrlMore("")
          setPublishedAt("")
          localStorage.clear()
          navigate('/blog/' + form_object.slug)
        },
        (error) => {
          // One for error
          console.log('ERROR while saving the form data: ', error.toString());
          setSaveErrors(true);
        }
      );
  }

  const [userEmail, setUserEmail] = useState (null)
  useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              // const uid = user.uid;
              // ...
              setUserEmail(user.email)
            } else {
              // User is signed out
              // ...
              setUserEmail(null)
            }
          });

    }, [])


  const handleSubmit = (e) => {
    e.preventDefault();
    let validationStatus = [];
    validationStatus.push(e.target.elements[0].validity.valid);
    validationStatus.push(e.target.elements[1].validity.valid);
    validationStatus.push(e.target.elements[2].validity.valid);
    validationStatus.push(e.target.elements[3].validity.valid);
    validationStatus.push(e.target.elements[4].validity.valid);
    validationStatus.push(e.target.elements[5].validity.valid); // MDBootstrap form shenanigans doesnt allow usage of a child id
    validationStatus.push(e.target.elements[6].validity.valid);
    validationStatus.push(e.target.elements[7].validity.valid);
    validationStatus.push(e.target.elements[8].validity.valid);
    validationStatus.push(e.target.elements[9].validity.valid);
    validationStatus.push(e.target.elements[10].validity.valid);
    let formValidator = arr => arr.every(v => v === true);
    let formIsValid = formValidator(validationStatus);
    if (formIsValid) {
      sendFormData();
    } else {
      setFormErrors(true);
    }
  }

  const article = {
    form_error: {
      title: {
        ro: 'Formularul are erori!',
        en: 'Form errors!'
      },
      time: {
        ro: 'Chiar acum',
        en: "Just now"
      },
      text: {
        ro: 'Te rugăm să corectezi erorile din chenarele roșii.',
        en: "Please correct the errors in the red boxes."
      }
    },
    send_error: {
      title: {
        ro: 'Eroare trimitere',
        en: 'Sending error'
      },
      time: {
        ro: 'Chiar acum',
        en: "Just now"
      },
      text: {
        ro: 'Te rugăm să încerci mai târziu. Nu-ți fă griji, am salvat datele din formular în browserul tău. Trebuie doar să te întorci la site și să dai submit acestui formular când ai un minut liber.',
        en: "Please try again later. Don't worry, we saved the form data to your browser. You only have to come back to the website and submit this form again whenever you have a minute."
      }
    },
    headline: {
      ro: 'Adaugă articol',
      en: "Add article"
    },
    category: {
      ro: 'Categorie',
      en: "Category"
    },
    category_choose: {
      ro: 'Alege o categorie',
      en: "Choose a category"
    },
    slug: {
      ro: 'Slug',
      en: "Slug"
    },
    title_ro: {
      ro: 'Titlu în română',
      en: "Title in romanian"
    },
    title_en: {
      ro: 'Titlu în engleză',
      en: "Title in english"
    },
    text_ro: {
      ro: 'Text în română',
      en: "Text in romanian"
    },
    text_en: {
      ro: 'Text în engleză',
      en: "Text in english"
    },
    long_text_ro: {
      ro: 'Text lung în română',
      en: "Long text in romanian"
    },
    long_text_en: {
      ro: 'Text lung în engleză',
      en: "Long text in english"
    },
    url_img: {
      ro: 'URL pentru imagine (opțional)',
      en: "Image URL (optional)"
    },
    url_more: {
      ro: 'URL pentru a citi mai multe (opțional)',
      en: "URL to read more (optional)"
    },
    published_at: {
      ro: 'Data publicării (opțional)',
      en: "Publish date (optional)"
    },
    send: {
      ro: 'Trimite',
      en: 'Send'
    }
  }

  useEffect(() => {
    localStorage.setItem("new_article_category", JSON.stringify(articleCategory));
  }, [articleCategory]);

  useEffect(() => {
    localStorage.setItem("new_article_slug", JSON.stringify(articleSlug));
  }, [articleSlug]);

  useEffect(() => {
    localStorage.setItem("new_article_title_ro", JSON.stringify(titleRo));
  }, [titleRo]);

  useEffect(() => {
    localStorage.setItem("new_article_title_en", JSON.stringify(titleEn));
  }, [titleEn]);

  useEffect(() => {
    localStorage.setItem("new_article_text_ro", JSON.stringify(textRo));
  }, [textRo]);

  useEffect(() => {
    localStorage.setItem("new_article_text_en", JSON.stringify(textEn));
  }, [textEn]);

  useEffect(() => {
    localStorage.setItem("new_article_long_text_ro", JSON.stringify(longTextRo));
  }, [longTextRo]);

  useEffect(() => {
    localStorage.setItem("new_article_long_text_en", JSON.stringify(longTextEn));
  }, [longTextEn]);

  useEffect(() => {
    localStorage.setItem("new_article_url_img", JSON.stringify(urlImg));
  }, [urlImg]);

  useEffect(() => {
    localStorage.setItem("new_article_url_more", JSON.stringify(urlMore));
  }, [urlMore]);

  useEffect(() => {
    localStorage.setItem("new_article_published_at", JSON.stringify(publishedAt));
  }, [publishedAt]);


  return (
    <>
    <MDBToast
        color='danger'
        position='top-right'
        delay={2000}
        appendToBody
        show={formErrors}
        headerContent={
          <>
            <strong className='me-auto'>{ article.form_error.title[lang] }</strong>
            <small>{ article.form_error.time[lang] }</small>
          </>
        }
        bodyContent={ article.form_error.text[lang] }
      />
      <MDBToast
          color='danger'
          position='top-right'
          delay={2000}
          appendToBody
          show={saveErrors}
          headerContent={
            <>
              <strong className='me-auto'>{ article.send_error.title[lang] }</strong>
              <small>{ article.send_error.time[lang] }</small>
            </>
          }
          bodyContent={ article.send_error.text[lang] }
        />
    <SEO
      title={ layout['brand'] }
      description={ layout.sections.contact[lang] }
      name={ layout['brand'] }
      type="website"
    />
    <MDBContainer>
      <MDBRow>
        <MDBCol xs='12' md='6' offsetMd='3' className="p-5">
          <h2>{ article.headline[lang] }</h2>
          <hr />
          <MDBValidation onSubmit={handleSubmit}>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBSelect id='category' label={ article.category[lang] }
                    data={[
                      { text: 'Project', value: 'Project', defaultSelected: true },
                      { text: 'Software', value: 'Software' },
                      { text: 'Cloud', value: 'Cloud' }
                    ]}
                    visibleOptions="3"
                    value={articleCategory}
                    onValueChange={(e) => setArticleCategory(e.value)}
                    validFeedback=''
                    validation
                    required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4 mb-4'>
              <MDBCol>
                  <MDBValidationItem feedback=''>
                    <MDBInput className='' type='text' id='slug' label={ article.slug[lang] }
                    value={articleSlug}
                    onChange={(e) => setArticleSlug(e.target.value)}
                    required
                    />
                  </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='text' id='title_ro' label={ article.title_ro[lang] }
                  value={titleRo}
                  onChange={(e) => setTitleRo(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBInput className='' type='text' id='title_en' label={ article.title_en[lang] }
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='text_ro' label={ article.text_ro[lang] } rows={4}
                  value={textRo}
                  onChange={(e) => setTextRo(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='text_en' label={ article.text_en[lang] } rows={4}
                  value={textEn}
                  onChange={(e) => setTextEn(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='long_text_ro' label={ article.long_text_ro[lang] } rows={10}
                  value={longTextRo}
                  onChange={(e) => setLongTextRo(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBValidationItem feedback=''>
                  <MDBTextArea id='long_text_en' label={ article.long_text_en[lang] } rows={10}
                  value={longTextEn}
                  onChange={(e) => setLongTextEn(e.target.value)}
                  required
                  />
                </MDBValidationItem>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mt-4 mb-4'>
              <MDBCol>
                  <MDBValidationItem feedback=''>
                    <MDBInput className='' type='text' id='url_img' label={ article.url_img[lang] }
                    value={urlImg}
                    onChange={(e) => setUrlImg(e.target.value)}
                    />
                  </MDBValidationItem>
              </MDBCol>
            </MDBRow>
            <MDBRow className='mt-4 mb-4'>
              <MDBCol>
                  <MDBValidationItem feedback=''>
                    <MDBInput className='' type='text' id='url_more' label={ article.url_more[lang] }
                    value={urlMore}
                    onChange={(e) => setUrlMore(e.target.value)}
                    />
                  </MDBValidationItem>
              </MDBCol>
            </MDBRow>

            <MDBRow className='mt-4 mb-4'>
              <MDBCol>
                  <MDBValidationItem feedback=''>
                    <MDBDatepicker className='' id='published_at' label={ article.published_at[lang] } inputToggle format='yyyy-mm-dd'
                    value={publishedAt}
                    onChange={(date) => setPublishedAt(date)}
                    />
                  </MDBValidationItem>
              </MDBCol>
            </MDBRow>


            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBCaptcha className='g-recaptcha' siteKey='6Ldj4ZknAAAAABO6TvrI5vG874BWRL4wI-_dsCom' lang={ lang } />
              </MDBCol>
            </MDBRow>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBBtn type='submit' block style={{backgroundColor: layout['color_main'] }}>
                  { article.send[lang] }
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBValidation>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    </>
  );
}
