import { React } from 'react';
import { useWebsite } from '../hooks/useWebsite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinnerThird
} from '@fortawesome/pro-duotone-svg-icons'

export default function Loading() {
  const { layout, hostname, lang, langRo, langEn } = useWebsite();
  return (
    <div className="m-5 p-5 d-flex justify-content-center align-items-center">
      <div className="m-3 p-3">
        <FontAwesomeIcon size='10x' className='m-5 p-5' icon={faSpinnerThird} spin style={{color: layout['color_main'] }} />
      </div>
    </div>
  )
}
