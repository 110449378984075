import { useWebsite } from '../hooks/useWebsite';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBCardTitle,
   MDBAccordion,
   MDBAccordionItem
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleQuestion
} from '@fortawesome/pro-duotone-svg-icons'
import pm_1 from "../assets/project_management_waterfall.png";
import pm_2 from "../assets/project_management_hybrid.png";
import pm_3 from "../assets/project_management_agile.png";
import sa_1 from "../assets/software_architect_custom.png";
import sa_2 from "../assets/software_architect_hq.png";
import sa_3 from "../assets/software_architect_prod.png";
import sa_4 from "../assets/software_architect_os.png";
import sa_5 from "../assets/software_architect_docker.png";
import sa_6 from "../assets/software_architect_gapps.png";
import sa_7 from "../assets/software_architect_migrate.png";
import ca_1 from "../assets/cloud_architect_server.png";
import ca_2 from "../assets/cloud_architect_scale.png";
import ca_3 from "../assets/cloud_architect_template.png";
import ca_4 from "../assets/cloud_architect_k8s.png";
import ca_5 from "../assets/cloud_architect_serverless.png";
import ca_6 from "../assets/cloud_architect_storage.png";
import ca_7 from "../assets/cloud_architect_db.png";
import ca_8 from "../assets/cloud_architect_network.png";
import ca_9 from "../assets/cloud_architect_other.png";
import ca_10 from "../assets/cloud_architect_orchestration.png";
import SEO from './SEO';
import ContactButton from './ContactButton'
import Articles from './Articles'


export default function LandingPage() {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()


  const hero = {
    'Octavian Gherghiniș': {
      value: {
        ro: 'Viața e prea interesantă ',
        en: "Life is too interesting"
      },
      target: {
        ro: 'ca să te concentrezi pe un singur lucru',
        en: "to focus on a single thing"
      },
      how: {
        ro: 'Având o abordare generalistă înseamnă că indiferent de provocare, pot găsi o soluție. Pot face asta pentru că am o gamă largă de abilități și experiență, și mereu învăț lucruri noi.',
        en: 'Having a generalist approach means that regardless of challenge, I can find a solution. I can do this because I have a wide range of skills and experience, and I am always learning new things.'
      },
      ps: {
        ro: '',
        en: ''
      }
    },
    'Software Architect': {
        value: {
          ro: 'Soluții software la nivel de Corporație, ',
          en: "Corporation level software solutions, "
        },
        target: {
          ro: 'pentru IMM-uri românești, antreprenori și freelanceri',
          en: "for romanian SMBs, entrepreneurs and freelancers"
        },
        how: {
          ro: 'Dacă te întrebi uneori "oare am putea pune calculatorul să..." ai ajuns în locul potrivit. Majoritatea întrebărilor de acest gen au un răspuns afirmativ. Întrebarea reală este "cum să o facem ?"',
          en: 'If you sometimes wonder "could we use the computer to..." you are in the right place. Most of these questions have an affirmative answer. The real question is "how should we do it ?"'
        }
      },
      'Cloud Architect': {
          value: {
            ro: 'Soluții Cloud de la simplu la complex, ',
            en: "Simple to complex Cloud solutions, "
          },
          target: {
            ro: 'pentru IMM-uri românești, antreprenori și freelanceri',
            en: "for romanian SMBs, entrepreneurs and freelancers"
          },
          how: {
            ro: 'Vrei să faci ceva de milioane de ori pe minut ? Lansezi un serviciu nou și crezi că vei avea 10 000 de utilizatori în seara respectivă ? Ai un API pentru serviciile tale și vrei să îl monetizezi ?',
            en: "Do you want to do something millions of times a minute ? Are you launching a new service and you think you'll have 10 000 users that evening ? Do you have an API for your services that you'd like to monetize ?"
          }
        },
        'Project Manager': {
            value: {
              ro: 'Management de proiect IT / Software, ',
              en: "IT / Software project management, "
            },
            target: {
              ro: 'pentru IMM-uri românești, antreprenori și freelanceri',
              en: "for romanian SMBs, entrepreneurs and freelancers"
            },
            how: {
              ro: 'Știi exact ce vrei să construiești ? Super, lucrăm într-un fel. Știi o parte din ce vrei, dar o altă parte e plină de necunoscute ? Lucram în alt fel. Ai o idee dar nu știi exact cum să ajungi la ea ? Știm să lucrăm și așa.',
              en: "Do you know exactly what you want to build ? Great, we'll work like that. Do you know a part of what you want, but the other part is full of unknowns ? We'll work in a different way. You have an idea, but you don't know exactly how to get to it ? We know what to do."
            }
          },
  }
  const services = {
    header: {
      ro: 'Exemple de servicii',
      en: "Service examples"
    },
    header_project_manager: {
      ro: 'Manager de proiect IT / Software',
      en: "IT / Software project manager"
    },
    header_software_architect: {
      ro: 'Arhitect software',
      en: "Software architect"
    },
    header_cloud_architect: {
      ro: 'Arhitect Cloud',
      en: "Cloud architect"
    },
    'Software Architect': [
      {
        id: "1",
        name: {
          ro: 'Software la comandă',
          en: "Custom software"
        },
        text: {
          ro: 'Cod scris în mod specific pentru nevoile tale, care face exact ce vrei tu.',
          en: "Code written specifically for your needs, doing exactly what you need it to."
        },
        image: sa_1,
      },
      {
        id: "2",
        name: {
          ro: 'Sediu digital',
          en: "Digital HQ"
        },
        text: {
          ro: 'Unul sau mai multe domenii, configurare email pe infrastructura Google (ca Gmail), grupuri de email, permisiuni și setări de securitate.',
          en: "One or more domains, email configuration on Google infrastructure (like Gmail), email groups, permissions and security settings."
        },
        image: sa_2,
      },
      {
        id: "3",
        name: {
          ro: 'Selecție aplicații productivitate',
          en: "Choosing productivity apps"
        },
        text: {
          ro: 'Care din miile de aplicații de productivitate se potrivesc echipei tale ?',
          en: "Which of the thousands of productivity apps are right for your team ?"
        },
        image: sa_3,
      },
      {
        id: "4",
        name: {
          ro: 'Configurare servicii OpenSource',
          en: "OpenSource service configuration"
        },
        text: {
          ro: 'Relaxează-te când vine vorba de Linux, servere web, load balancere, storage, backup, virtualizare și așa mai departe.',
          en: "Relax when it comes to Linux, web servers, load balancers, storage, backup, logging and so on."
        },
        image: sa_4,
      },
      {
        id: "5",
        name: {
          ro: 'Docker / Kubernetes',
          en: "Docker / Kubernetes"
        },
        text: {
          ro: 'Primul pas ca să poți rula softul tău oriunde este să îl pregătești pentru virtualizare.',
          en: "The first step towards running your software anywhere is to package it for virtualization."
        },
        image: sa_5,
      },
      {
        id: "6",
        name: {
          ro: 'Google Apps Script',
          en: "Google Apps Script"
        },
        text: {
          ro: 'Dacă folosești deja Google Workspace, atunci trebuie să știi că toate produsele pot comunica între ele.',
          en: "If you already use Google Workspace, you should know all the products can communicate with each other."
        },
        image: sa_6,
      },
      {
        id: "7",
        name: {
          ro: 'Transfer de date între aplicații',
          en: "Data transfer between apps"
        },
        text: {
          ro: 'Echipa ta lucrează într-o aplicație, dar ai vrea să folosești date din ea în altă aplicație ?',
          en: "Your team is using an app, but youțd like to use data from it in another app ?"
        },
        image: sa_7,
      },

    ],
    'Cloud Architect': [
      {
        id: "1",
        name: {
          ro: 'Configurare servere',
          en: "Server configuration"
        },
        text: {
          ro: 'Alegere tip de servere și sistem de operare în funcție de softul care trebuie să ruleze pe ele.',
          en: "Choosing server type and operating system according to the software that must run on it."
        },
        image: ca_1,
      },
      {
        id: "2",
        name: {
          ro: 'Scalare servere',
          en: "Server scaling"
        },
        text: {
          ro: 'Alcătuirea de grupuri de servere care se pot micșora sau mări în funcție de încărcare.',
          en: "Creating server groups that can scale down or up depending on the workload."
        },
        image: ca_2,
      },
      {
        id: "3",
        name: {
          ro: 'Șabloane pentru servere',
          en: "Server templates"
        },
        text: {
          ro: 'Alcătuirea de șabloane pentru de servere, care conțin toate softurile necesare pentru serviciile principale.',
          en: "Creating server templates that contain all the required software for the main services."
        },
        image: ca_3,
      },
      {
        id: "4",
        name: {
          ro: 'Configurare Kubernetes',
          en: "Kubernetes configuration"
        },
        text: {
          ro: 'Configurarea unui cluster Kubernetes pentru aplicația sau aplicațiile necesare.',
          en: "Configuring a Kubernetes cluster for the required app or apps."
        },
        image: ca_4,
      },
      {
        id: "5",
        name: {
          ro: 'Configurare servicii serverless',
          en: "Serverless service configuration"
        },
        text: {
          ro: 'Configurarea serviciilor care nu necesită servere, fiind gestionate de furnizorul de Cloud.',
          en: "Configuring serverless services, which are managed by the Cloud provider."
        },
        image: ca_5,
      },
      {
        id: "6",
        name: {
          ro: 'Configurare stocare în Cloud',
          en: "Cloud storage configuration"
        },
        text: {
          ro: 'Configurarea serviciilor specializate în stocarea datelor în Cloud, optimizând costurile.',
          en: "Configuring Cloud storage services while optimizing cost."
        },
        image: ca_6,
      },
      {
        id: "7",
        name: {
          ro: 'Configurare baze de date',
          en: "Database configuration"
        },
        text: {
          ro: 'Configurarea bazelor de date (tip SQL, schemaless și în memorie) gestionate de furnizorul de Cloud.',
          en: "Configuring Cloud databases (SQL-like, schemaless and in-memory)."
        },
        image: ca_7,
      },
      {
        id: "8",
        name: {
          ro: 'Configurare rețele Cloud',
          en: "Cloud network configuration"
        },
        text: {
          ro: 'Configurarea rețelelor din Cloud, luând în calcul echilibrul dintre nevoi și securitate.',
          en: "Configuring Cloud networks, taking into account the balance between requirements and security."
        },
        image: ca_8,
      },
      {
        id: "9",
        name: {
          ro: 'Alte servicii Cloud',
          en: "Other Cloud services"
        },
        text: {
          ro: 'Configurarea altor servicii din Cloud, în funcție de nevoile identificate.',
          en: "Configuring other Cloud services, taking into account all the identified requirements."
        },
        image: ca_9,
      },
      {
        id: "10",
        name: {
          ro: 'Orchestrare Cloud',
          en: "Cloud orchestration"
        },
        text: {
          ro: 'Documentarea tuturor serviciilor și configurațiilor folosite într-un software care permite ca totul sa fie (re)făcut de la zero.',
          en: "Documenting all the services and their configurations using a software that allows everything to be (re)built from the ground up."
        },
        image: ca_10,
      },
    ],
    'Project Manager': [
      {
        id: "1",
        name: {
          ro: 'Metodologie Waterfall',
          en: "Waterfall methodology"
        },
        text: {
          ro: 'Când știm exact ce avem de făcut ne concentrăm pe planificare și execuție.',
          en: "When we know exactly what we need to do, we focus on planning and execution."
        },
        image: pm_1,
      },
      {
        id: "2",
        name: {
          ro: 'Metodologie Hibrid',
          en: "Hybrid methodology"
        },
        text: {
          ro: 'Când știm doar o parte din ce avem de făcut ne concentrăm pe capabilitatea de a putea încorpora funcționalități neprevăzute.',
          en: "When we only know a part of what we need to do, we focus on the capability to integrate unforseen features."
        },
        image: pm_2,
      },
      {
        id: "2",
        name: {
          ro: 'Metodologie Agile',
          en: "Agile methodology"
        },
        text: {
          ro: 'Când avem o idee nu foarte bine definită despre unde vrem să ajungem, ne concentrăm pe validarea incrementală a rezultatelor.',
          en: "When we don't have a very clear idea of where we want to go, we focus on incrementally validating our results."
        },
        image: pm_3,
      },
    ]
  }
  const faq = {
    header: {
      ro: 'Întrebări frecvente',
      en: "Frequently asked questions"
    },
    'common': {
        questions: [
          {
            q: {
              ro: 'Ce fac dacă am nevoie de ceva care nu este printre exemplele de servicii oferite ?',
              en: "What do I do if I need something that is not among the service examples ?"
            },
            a: {
              ro: 'Folosești formularul de contact și întrebi dacă te putem ajuta.',
              en: "You use the contact from and you as if we can help."
            }
          },
          {
            q: {
              ro: 'Cât de scumpe sunt serviciile oferite ?',
              en: "How expensive are your services ?"
            },
            a: {
              ro: 'Fiecare proiect este evaluat individual și fiecare ofertă de preț reflectă complexitatea și prioritățile tale. Cu alte cuvinte, proiectele simple tind să fie ieftine, pe când proiectele complexe și urgente tind să fie scumpe.',
              en: "Each project is evaluated individually and each offer reflects the complexity and your priorities. In other words, simple projects tend to be cheap, while complex and urgent projects tend to be expensive."
            }
          },
          {
            q: {
              ro: 'O să știu costurile operaționale pentru soluțiile oferite ?',
              en: "Will I know the operational cost for the solutions you're offering ?"
            },
            a: {
              ro: 'Da, fiecare soluție vine la pachet cu informații detaliate despre costurile operaționale.',
              en: "Yes, each solution comes with detailed information about operational costs."
            }
          },
          {
            q: {
              ro: 'Ce fac dacă vreau să consolidez costurile ?',
              en: "What can I do if I want to consolidate costs ?"
            },
            a: {
              ro: 'Putem încheia un contract în care noi ne ocupăm de tot, iar tu și echipa ta doar beneficiați de soluție.',
              en: "We can have a single contract for us to handle everything, while you and your team just use the solution."
            }
          },
          {
            q: {
              ro: 'Cum procedăm cu proprietatea intelectuală ?',
              en: "How do we handle intellectual property ?"
            },
            a: {
              ro: 'Condițiile transferului proprietății intelectuale sunt stipulate în contract. În general, aceasta se întâmplă odata cu plata.',
              en: "The conditions for the intellectual property transfer are detailed in the contract. Generally, this happens once the payment is done."
            }
          },
        ]
    },
    'Software Architect': {
      questions: [

      ]
    },
    'Cloud Architect': {
        questions: [
        ]
      },
      'Project Manager': {
          questions: [
          ]
        },
  }


  return (
    <>
    <SEO
      title={ layout['brand'] }
      description={ hero[layout['brand']].value[lang] }
      name={ layout['brand'] }
      type="website"
    />

    {(hostname !== layout['umbrella_hostname']) ? (
    <>
    <MDBContainer fluid style={{ backgroundColor: layout['color_secondary']}}>
      <MDBRow>
        <MDBCol className="p-5">
        <h1 className="d-flex justify-content-center"><b>{ hero[layout['brand']].value[lang] }</b></h1>
        <h3 className="d-flex justify-content-center">{ hero[layout['brand']].target[lang] }</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol offsetMd='3' md='6' className="ps-5 pe-5 pb-5 d-flex justify-content-center font-monospace lh-lg">
          <p>
            { hero[layout['brand']].how[lang] }
          </p>
        </MDBCol>

      </MDBRow>
    </MDBContainer>

    <MDBContainer>
      <MDBRow><MDBCol className="mt-5 mb-0 border-bottom"><h2>{ services.header[lang] }</h2></MDBCol></MDBRow>
      <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-5">
      {services[layout['brand']].map((service, i) => {
        return (
          <MDBCol key={i}>
            <MDBCard className="h-100">
              <MDBCardImage src={ service.image } alt='...' position='top' />
              <MDBCardBody style={{ backgroundColor: layout['color_main'] }} className="text-white">
                <MDBCardTitle>{ service.name[lang] }</MDBCardTitle>
                <MDBCardText style={{ color: layout['color_faded'] }}>
                  { service.text[lang] }
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        )
      })}
      </MDBRow>
      <MDBRow><MDBCol className="mt-5 mb-7 border-bottom"><h2>{ faq.header[lang] }</h2></MDBCol></MDBRow>
      <MDBRow>
        <MDBCol className="mt-3 mb-5">
          <MDBAccordion initialActive={0}>
          {faq[layout['brand']]['questions'].map((question, i) => {
            return (
              <MDBAccordionItem key={i} collapseId={i} headerTitle={<><FontAwesomeIcon size='lg' className='me-2' icon={faCircleQuestion} /><span>{question.q[lang]}</span> </>}>
                { question.a[lang] }
              </MDBAccordionItem>
            )
          })}
          {faq['common']['questions'].map((question, i) => {
            return (
              <MDBAccordionItem key={i} collapseId={i} headerTitle={<><FontAwesomeIcon size='lg' className='me-2' icon={faCircleQuestion} /><span>{question.q[lang]}</span> </>}>
                { question.a[lang] }
              </MDBAccordionItem>
            )
          })}
          </MDBAccordion>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <ContactButton />
    </>
    ) : (
      /*<MDBContainer>
      <MDBRow>
      <MDBCol>
      <Articles />
      </MDBCol>
      </MDBRow>
    </MDBContainer>*/
    <>
    <MDBContainer fluid style={{ backgroundColor: layout['color_secondary']}}>
      <MDBRow>
        <MDBCol className="p-5">
        <h1 className="d-flex justify-content-center"><b>{ hero[layout['brand']].value[lang] }</b></h1>
        <h3 className="d-flex justify-content-center">{ hero[layout['brand']].target[lang] }</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol offsetMd='3' md='6' className="ps-5 pe-5 pb-5 d-flex justify-content-center font-monospace lh-lg">
          <p>
            { hero[layout['brand']].how[lang] }
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    <MDBContainer>
    <MDBRow><MDBCol className="mt-5 mb-0 border-bottom"><h2>{ services.header_project_manager[lang] }</h2></MDBCol></MDBRow>
    <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-5">
    {services['Project Manager'].map((service, i) => {
      return (
        <MDBCol key={i}>
          <MDBCard className="h-100">
            <MDBCardImage src={ service.image } alt='...' position='top' />
            <MDBCardBody style={{ backgroundColor: layout['color_main'] }} className="text-white">
              <MDBCardTitle>{ service.name[lang] }</MDBCardTitle>
              <MDBCardText style={{ color: layout['color_faded'] }}>
                { service.text[lang] }
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      )
    })}
    </MDBRow>
    <MDBRow><MDBCol className="mt-5 mb-0 border-bottom"><h2>{ services.header_software_architect[lang] }</h2></MDBCol></MDBRow>
    <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-5">
    {services['Software Architect'].map((service, i) => {
      return (
        <MDBCol key={i}>
          <MDBCard className="h-100">
            <MDBCardImage src={ service.image } alt='...' position='top' />
            <MDBCardBody style={{ backgroundColor: layout['color_main'] }} className="text-white">
              <MDBCardTitle>{ service.name[lang] }</MDBCardTitle>
              <MDBCardText style={{ color: layout['color_faded'] }}>
                { service.text[lang] }
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      )
    })}
    </MDBRow>
    <MDBRow><MDBCol className="mt-5 mb-0 border-bottom"><h2>{ services.header_cloud_architect[lang] }</h2></MDBCol></MDBRow>
    <MDBRow className="mt-5 mb-5 row-cols-1 row-cols-md-1 row-cols-lg-3  g-5">
    {services['Cloud Architect'].map((service, i) => {
      return (
        <MDBCol key={i}>
          <MDBCard className="h-100">
            <MDBCardImage src={ service.image } alt='...' position='top' />
            <MDBCardBody style={{ backgroundColor: layout['color_main'] }} className="text-white">
              <MDBCardTitle>{ service.name[lang] }</MDBCardTitle>
              <MDBCardText style={{ color: layout['color_faded'] }}>
                { service.text[lang] }
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      )
    })}
    </MDBRow>
    </MDBContainer>
    </>





    )}
    </>
  );
}
