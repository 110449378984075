import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
//import { Link, useNavigate } from 'react-router-dom';
import { useWebsite } from '../hooks/useWebsite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  faUserPlus
} from '@fortawesome/pro-duotone-svg-icons'


export default function Footer() {
  const { layout, hostname, lang, langRo, langEn } = useWebsite()
  return (
    <>
    <MDBFooter  className='floating-bottom text-white'>
    <MDBContainer fluid style={{ backgroundColor: layout['color_main'] }} >
        <MDBRow>
          <MDBCol style={{ backgroundColor: layout['color_secondary'], borderBottom: "2px solid " + layout['color_accent'] }} col='12' className='d-flex p-2 text-dark align-items-center justify-content-center'> <FontAwesomeIcon size='2x' icon={icon({name: 'face-awesome', family: 'duotone', style: 'solid'})} className='me-3 xtext-dark' style={{ color: layout['color_accent']}} />{ layout['footer_funnel'][layout['brand']][lang] }
          </MDBCol>
        </MDBRow>
        <MDBRow className='mt-4 mb-4 pt-4'>
          {(hostname !== layout['umbrella_hostname']) ? (
            layout['footer_links'].map((l, i) => {
              if (layout.brand !== l.brand) {
                return (
                  <MDBCol key={i} col='4' className='text-center'>
                    <a href={l.href} rel="noreferrer" target="_blank" className="text-white"><FontAwesomeIcon className='me-2' icon={l['icon']} /><b>{l.brand}</b></a>
                    <p>{ l.text[lang] }</p>
                  </MDBCol>
                )
              }
              return null;
            })
          ) : (
            <>
            {layout['footer_qualifications'].map((l, i) => {
                return (
                  <MDBCol key={i} col='4' className="">
                    <div className="d-flex align-items-center justify-content-center"><FontAwesomeIcon size="2x" className='me-2' icon={l['icon']} /><b>{l.name}</b></div>
                    <p className="d-flex justify-content-center">{ l['text'][lang] }</p>
                    
                  </MDBCol>
                )
            })}
            <MDBCol col='4' className="">
              <div className="d-flex align-items-center justify-content-center"><FontAwesomeIcon size="2x" className='me-2' icon={ faUserPlus } /><b>Contact</b></div>
              <p className="d-flex justify-content-center"><a className="text-white" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/ogherghinis/">linkedin / ogherghinis</a></p>
            </MDBCol>
            </>

          )}


        </MDBRow>
        <MDBRow>
          <MDBCol col='12' className='p-2 mt-0 mb-3 text-small text-center'>© {new Date().getFullYear()} <b>{ layout['brand'] }</b>
          {(hostname !== layout['umbrella_hostname']) ? (
            <>
            <p class="mb-0">Moonbase SRL, Strada Grigore Ionescu 63, bl T 73</p>
            <p> sc 2, et 4, ap 42, Camera 2, Bucuresti, Romania</p>
            </>
          ) : null}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
    </>
  );
}
