// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';

import {
  getFirestore,
  query,
  doc,
  getDocs,
  getDoc,
  updateDoc,
  collection,
  where,
  limit,
  addDoc,
  setDoc,
  orderBy,
  serverTimestamp,
  Timestamp
} from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1VOWZINNwGhCp7j9sxUkAF3d1UqBfwzs",
  authDomain: "consulting-funnel.firebaseapp.com",
  projectId: "consulting-funnel",
  storageBucket: "consulting-funnel.appspot.com",
  messagingSenderId: "312492426767",
  appId: "1:312492426767:web:b02945920194f080fbc57a",
  measurementId: "G-CL9SN03K2F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Save contact form to Firestore
const saveContactForm = async (form_object) => {
    form_object.timestamp = new serverTimestamp()
    // Because this is a promise, we don't use try/catch
    const docRef = await addDoc(collection(db, "contact_forms"), form_object);
    return docRef.id
/*
    .then(
      // Instead we use then with two arguments,
      (result) => {
        // One for success
        console.log(result);
        what_happened = result
      },
      (error) => {
        // And one for failure
        console.log(error);
        what_happened = error
      }
    );
*/
}

// Add article to Firestore
const saveArticleForm = async (form_object) => {
    const now = new serverTimestamp()
    form_object.timestamp = now
    if (form_object.published_at) {
      // this is optional, so if a date exists in the form, use it
      const pdate = new Date(form_object.published_at + 'T00:00:00.000Z');
      form_object.published_at = Timestamp.fromDate(pdate)
      form_object.modified_at = Timestamp.fromDate(pdate) // default value
    } else {
      // by default we just use .now()
      form_object.published_at = Timestamp.now()
      form_object.modified_at = Timestamp.now() // default value
    }
    // Because this is a promise, we don't use try/catch
    const docRef = await addDoc(collection(db, "articles"), form_object);
    return docRef.id
/*
    .then(
      // Instead we use then with two arguments,
      (result) => {
        // One for success
        console.log(result);
        what_happened = result
      },
      (error) => {
        // And one for failure
        console.log(error);
        what_happened = error
      }
    );
*/
}

const getArticles = async (filter = {limit: 100, category: '*'}) => {
  const articlesRef = collection(db, "articles");
  const limit_no = filter.limit;
  const articlesList = [];
  const constraints = []
  if (filter.category != '*') {
    var catRef = where("category", "==", filter.category)
  } else {
    var catRef = where("category", "in", ['Project', 'Cloud', 'Software'])
  }
  constraints.push(catRef)
  // update the query with a generally applicable rule: only select articles already published
  var publishRef = where('modified_at', '<=', Timestamp.now())
  constraints.push(publishRef)
  // this query depends on a composite index (category, modified_at)
  // and the orderBy must be present and must be the same direction as the composite index
  let q = query(articlesRef, ...constraints, orderBy("modified_at", "desc"))
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    articlesList.push(doc.data())
  });
  return articlesList
}

const getArticle = async (filter = {limit: 1, slug: '-'}) => {
  const articlesRef = collection(db, "articles");
  let whereSlugRef = where("slug", "==", filter.slug)
  const limit_no = filter.limit;
  const articlesList = [];
  let q = query(articlesRef, whereSlugRef, limit(limit_no));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    articlesList.push(doc.data())
  });
  if (articlesList.length > 0) {
    return articlesList
  } else {
    return undefined
  }

}

export {
  saveContactForm,
  saveArticleForm,
  getArticles,
  getArticle,
  auth,
  googleProvider,
  signInWithPopup,
  onAuthStateChanged
}
